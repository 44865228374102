export const gearDict = [
  'Berserker',
  'Assassin',
  'Harrier',
  'Commander',
  'Minstrel',
  'Magi',
  'Marauder',
  'Cleric',
  'Nomad',
  'Zealot',
  'Viper',
  'Sinister',
  'Grieving',
  'Seraph',
  'Marshal',
  'Giver',
  'Knight',
  'Trailblazer',
  'Plaguedoctor',
  'Carrion',
  'Rabid',
  'Dire',
  'Vigilant',
  'Valkyrie',
  'Cavalier',
  'Celestial',
  'Diviner',
  'Soldier',
  'Sentinel',
  'Wanderer',
  'Apothecary',
  'Shaman',
  'Crusader',
  'Rampager',
  'Settler',
  'Bringer',
  'Ritualist',
  'Dragon',
  'Custom',
  'BerserkerValkyrie',
  'RabidApothecary',
  'DireRabid',
];

export const professionDict = [
  'Warrior',
  'Revenant',
  'Guardian',
  'Ranger',
  'Engineer',
  'Elementalist',
  'Mesmer',
  'Necromancer',
  'Thief',
];

export const specializationDict = [
  'Spellbreaker',
  'Berserker',
  'Bladesworn',
  'Herald',
  'Renegade',
  'Vindicator',
  'Dragonhunter',
  'Firebrand',
  'Willbender',
  'Druid',
  'Soulbeast',
  'Untamed',
  'Scrapper',
  'Holosmith',
  'Mechanist',
  'Tempest',
  'Weaver',
  'Catalyst',
  'Chronomancer',
  'Mirage',
  'Virtuoso',
  'Scourge',
  'Reaper',
  'Harbinger',
  'Daredevil',
  'Deadeye',
  'Specter',
  'Warrior',
  'Revenant',
  'Guardian',
  'Ranger',
  'Engineer',
  'Elementalist',
  'Mesmer',
  'Necromancer',
  'Thief',
];

export const weaponTypeDict = ['Dual wield', 'Two-handed'];

export const runesDict = [
  'scholar',
  'scholar-without',
  'eagle',
  'eagle-perma',
  'thief',
  'spellbreaker',
  'flame-legion',
  'strength',
  'ogre',
  'ranger',
  'chronomancer',
  'fire',
  'pack',
  'leadership',
  'firebrand',
  'surging-and-ruby-orb',
  'surging',
  'radiance',
  'durability',
  'scrapper',
  'sanctuary',
  'herald',
  'rebirth',
  'monk',
  'water',
  'altruism',
  'rebirth-2',
  'flock',
  'berserker',
  'renegade',
  'elementalist',
  'lich',
  'trapper',
  'trapper-and-black-diamond',
  'nightmare',
  'tempest',
  'undead',
  'necromancer',
  'soulbeast',
  'aristocracy',
  'balthazar',
  'flame-legion-2',
  'baelfire',
  'krait',
  'mad-king',
  'afflicted',
  'thorns',
  'scavenging',
  'tormenting',
  'perplexity',
  'traveler',
  'divinity',
  'revenant',
  'exuberance',
  'vampirism',
  'firebrand-firebrand',
  'golemancer',
  'privateer',
  'krait-no-elite',
  'rage',
  'infiltration',
  'scholar-90',
  'eagle-never',
  'mesmer-0',
  'mesmer-100',
  'forgeman',
  'dolyak',
  'no-item-rune',
  'dragonhunter',
  'deadeye',
  'mesmer',
  'fireworks',
  'hoelbrak',
  'zephyrite',
  'guardian',
  'defender',
  'engineer',
  'reaper',
  'scourge',
  'druid',
  'mercy',
  'dwayna',
  'weaver',
  'sunless',
  'rata-sum',
  'lyssa',
  'adventurer',
  'daredevil',
  'warrior',
];

export const relicsDict = [
  'no-item-relic',
  'thief-relic',
  'fireworks-relic',
  'brawler-relic',
  'weaver-relic',
  'deadeye-relic',
  'citadel-relic',
  'cerus-relic',
  'dagda-relic',
  'wizards-tower-relic',
  'dragonhunter-relic',
  'isgarren-relic',
  'peitha-relic',
  'aristocracy-relic',
  'scourge-relic',
  'fractal-relic',
  'akeem-relic',
  'vass-relic',
  'sunless-relic',
  'afflicted-relic',
  'nightmare-relic',
  'mirage-relic',
  'krait-relic',
  'herald-relic',
  'firebrand-relic',
  'monk-relic',
  'cerus-relic-10',
  'relic-of-nourys',
  'demon-queen-relic',
  'midnight-king-relic',
  'pack-relic',
  'zephyrite-relic',
  'karakosa-relic',
  'flock-relic',
  'dwayna-relic',
  'lyhr-relic',
  'mercy-relic',
];

export const sigilDict = [
  'force',
  'accuracy',
  'impact/night/slaying-both',
  'impact/night/slaying-only-3',
  'severance-perma',
  'concentration',
  'transference',
  'malice',
  'bursting',
  'agony',
  'smoldering',
  'venom',
  'demons',
  'bloodlust',
  'cruelty',
  'perception',
  'corruption',
  'life',
  'bounty',
  'benevolence',
  'momentum',
  'stars',
  'platinum-doubloon',
  'hydromancy',
  'hydromancy-bonus',
  'geomancy',
  'earth',
  'doom',
  'torment',
  'mischief-ineptitude',
  'geomancy-torment-cfb5-virtues',
  'geomancy-torment-cfb8-virtues',
  'air-torment-cwb',
  'geomancy-torment-cwb',
  'geomancy-torment-cwb-alac',
  'geomancy-torment-harb',
  'air',
  'ruby-orb',
  'no-item-sigil',
  'fire-sigil',
  'earth-torment-harb',
  'air-earth-cwb',
  'blood-sigil',
  'draining-sigil',
  'leeching-sigil',
  'geomancy-doom-alac-specter',
];

export const enhancementDict = [
  'slaying-potion',
  'furious-sharpening-stone',
  'superior-sharpening-stone',
  'toxic-sharpening-stone',
  'magnanimous-sharpening-stone',
  'bountiful-sharpening-stone',
  'potent-lucent-oil',
  'toxic-maintenance-oil',
  'enhanced-lucent-oil',
  'furious-maintenance-oil',
  'magnanimous-maintenance-oil',
  'master-maintenance-oil',
  'corsair-maintenance-oil',
  'bountiful-maintenance-oil',
  'toxic-focusing-crystal',
  'furious-tuning-crystal',
  'master-tuning-crystal',
  'magnanimous-tuning-crystal',
  'bountiful-tuning-crystal',
  'holographic-super-apple',
  'holographic-super-drumstick',
  'holographic-super-cheese',
  'writ-of-masterful-accuracy',
  'writ-of-masterful-malice',
  'writ-of-masterful-strength',
  'potion-of-karka-toughness',
  'writ-of-learned-accuracy',
  'writ-of-calculated-accuracy',
  'writ-of-studied-accuracy',
  'writ-of-learned-malice',
  'writ-of-calculated-malice',
  'writ-of-learned-strength',
  'writ-of-calculated-strength',
  'corsair-tuning-crystal',
  'corsair-sharpening-stone',
  'decade-enhancement-station',
  'no-item-utility',
];

export const nourishmentDict = [
  'sweet-and-spicy-butternut-squash-soup',
  'truffle-steak',
  'curry-butternut-squash-soup',
  "dragon's-breath-bun",
  'seaweed-salad',
  'jerk-poultry',
  'sawgill-mushroom-risotto',
  'roasted-cactus',
  'fried-golden-dumpling',
  'soul-pastry',
  'eggs-benedict',
  'nopalitos-saute',
  'mussels-gnashblade',
  'fruit-salad-mint-garnish',
  'mint-creme-brulee',
  'delicious-rice-ball',
  'poultry-satay',
  'rare-veggie-pizza',
  'beef-rendang',
  'saffron-stuffed-mushroom',
  'steamed-red-dumpling',
  'fire-meat-chili',
  'fancy-truffle-burger',
  'truffle-risotto',
  'sweet-and-spicy-beans',
  'fire-flank-steak',
  'fancy-potato-and-leek-soup',
  'prickly-pear-pie',
  "dragon's-revelry-starcake",
  'birthday-blaster',
  'passion-fruit-tapioca-pudding',
  'prickly-pear-tapioca-pudding',
  'tropical-fruit-salad',
  'spicy-chocolate-cookie',
  'spicy-marinated-mushroom',
  'spicy-herbed-chicken',
  'truffle-ravioli',
  'omnomberry-bread',
  'kralkachocolate-bar',
  'chocolate-tapioca-pudding',
  'tropical-mousse',
  'elon-red',
  'fishy-rice-bowl',
  'plate-of-kimchi-pancakes',
  'bowl-of-kimchi-tofu-stew',
  'meaty-asparagus-skewer',
  'meaty-rice-bowl',
  'block-of-tofu',
  'dragons-revelry-starcake-bugged',
  'cilantro-lime-sous-vide-steak',
  'plate-of-coq-au-vin-with-salsa',
  'plate-of-beef-carpaccio-with-salsa-garnish',
  'salsa-eggs-benedict',
  'salsa-topped-veggie-flatbread',
  'cilantro-and-cured-meat-flatbread',
  'spherified-cilantro-oyster-soup',
  'slice-of-candied-dragon-roll',
  'slice-of-allspice-cake',
  'slice-of-allspice-cake-with-ice-cream',
  'avocado-smoothie',
  'strawberry-cilantro-cheesecake',
  'plate-of-poultry-aspic-with-salsa-garnish',
  'plate-of-clear-truffle-and-cilantro-ravioli',
  'spherified-oyster-soup-with-mint-garnish',
  'tray-of-decade-desserts',
  'no-item-food',
  'ghost-pepper-popper-reaper',
];

export const buffsDict = [
  'might',
  'fury',
  'protection',
  'vulnerability',
  'bannerOfStrength',
  'bannerOfDiscipline',
  'bannerOfTactics',
  'bannerOfDefense',
  'spotter',
  'frostSpirit',
  'empowerAllies',
  'pinpointDistribution',
  'assassinsPresence',
  'riteDwarf',
  'strengthInNumbers',
  'baneSignet',
  'signetOfJudgement',
  'signetOfMercy',
  'signetOfWrath',
  'exposed',
  'lightArmor',
  'jade-bot-base',
  'jade-bot-per-tier',
  'lightArmor2',
  'lightArmor3',
  'reinforced-armor',
];
