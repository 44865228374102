- section: Skills
  items:

    - id: signet-of-might
      text: Signet of Might
      modifiers:
        attributes:
          Power: [180, buff]
      gw2id: 14404
      temporaryBuff: false

    - id: signet-of-fury
      text: Signet of Fury
      modifiers:
        attributes:
          Precision: [180, buff]
      gw2id: 14410
      temporaryBuff: false

    - id: dolyak-signet
      text: Dolyak Signet
      modifiers:
        attributes:
          Toughness: [180, buff]
      gw2id: 14413
      temporaryBuff: false

- section: Strength
  id: 4
  items:

    - id: peak-performance
      text: Peak Performance
      subText: passive
      modifiers:
        damage:
          Outgoing Strike Damage: [5%, add]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [3%, add]
      gw2id: 1444
      defaultEnabled: true

    - id: peak-performance-2
      text: Peak Performance
      subText: active
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, add]
      gw2id: 1444
      defaultEnabled: false

    - id: forceful-greatsword
      text: Forceful Greatsword
      subText: base
      modifiers:
        attributes:
          Power: [120, converted] # from sheet by Bekko
      gw2id: 1338
      defaultEnabled: true
      temporaryBuff: false

    - id: forceful-greatsword-2
      text: Forceful Greatsword
      subText: with greatsword/spear
      amountData:
        label: '% uptime'
        default: 50
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [120, converted] # from sheet by Bekko
      gw2id: 1338
      defaultEnabled: false
      temporaryBuff: true # assume the user is on a dual-wield set

    - id: great-fortitude
      text: Great Fortitude
      modifiers:
        conversion:
          Vitality: {Power: 10%}
          Ferocity: {Power: 10%}
      gw2id: 1449
      defaultEnabled: true
      temporaryBuff: false

    - id: pinnacle-of-strength-crit
      text: Pinnacle Of Strength
      subText: passive
      minor: true
      modifiers:
        attributes:
          Critical Chance: 5%
      gw2id: 1453
      defaultEnabled: true
      temporaryBuff: false

    - id: pinnacle-of-strength
      text: Pinnacle Of Strength
      minor: true
      amountData:
        label: 'might'
        default: 25
        quantityEntered: 25
      modifiers:
        attributes:
          Power: [250, buff] # confirmed from bench hero panel
      gw2id: 1453
      defaultEnabled: true
      temporaryBuff: true

    - id: berserkers-power
      text: Berserker's Power
      amountData:
        label: 'stacks'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [5.25%, add]
      gw2id: 1437
      defaultEnabled: true

- section: Arms
  id: 36
  items:

    - id: furious-burst
      text: Furious Burst
      subText: 100% fury
      minor: true
      modifiers:
        attributes:
          Critical Chance: 5%
      gw2id: 1342
      defaultEnabled: true
      temporaryBuff: true

    - id: wounding-precision
      text: Wounding Precision
      modifiers:
        conversion:
          Expertise: {Precision: 7%}
      wvwModifiers:
        conversion:
          Expertise: {Precision: 4%}
      gw2id: 1455
      defaultEnabled: true
      temporaryBuff: false

    - id: signet-mastery
      text: Signet Mastery
      amountData:
        label: 'stacks'
        default: 5
        quantityEntered: 5
      modifiers:
        attributes:
          Ferocity: [500, converted]
      gw2id: 1344
      defaultEnabled: true
      temporaryBuff: true

    - id: deep-strikes-fury
      text: Deep Strikes (Fury)
      subText: with fury
      minor: true
      amountData:
        label: '% fury'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Condition Damage: [180, converted] # from sheet by Bekko
      gw2id: 1343
      defaultEnabled: true
      temporaryBuff: true

    - id: deep-strikes-bleeding
      text: Deep Strikes (Bleeding)
      subText: 'with bleeding (100% uptime)'
      minor: true
      modifiers:
        attributes:
          Critical Chance: 5%
      wvwModifiers:
        attributes:
          Critical Chance: 10%
      gw2id: 1343
      defaultEnabled: true
      temporaryBuff: true

    - id: unsuspecting-foe-full
      text: Unsuspecting Foe
      subText: 100% uptime # variable crit chance would be inaccurate
      modifiers:
        attributes:
          Critical Chance: 25%
      gw2id: 1315
      defaultEnabled: true
      temporaryBuff: true

    - id: burst-precision
      text: Burst Precision
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Ferocity: [150, unknown]
      gw2id: 1336
      defaultEnabled: true
      temporaryBuff: true

    - id: blade-master
      text: Blademaster
      subText: 'base, intended: 120 expertise'
      modifiers:
        attributes:
          Expertise: [120, converted] # confirmed from bench hero panel
      gw2id: 1333
      defaultEnabled: true
      temporaryBuff: false

    - id: blade-master-base-bugged
      text: Blademaster
      subText: 'base, bugged: 160 expertise'
      modifiers:
        attributes:
          Expertise: [160, converted]
      gw2id: 1333
      defaultEnabled: false
      temporaryBuff: false

    - id: blade-master-2
      text: Blademaster
      subText: with sword
      amountData:
        label: '% uptime'
        default: 50
        quantityEntered: 100
      modifiers:
        attributes:
          Condition Damage: [120, converted] # from sheet by Bekko
      gw2id: 1333
      defaultEnabled: true
      temporaryBuff: true

    - id: bloodlust
      text: Bloodlust
      subText: base
      minor: true
      modifiers:
        attributes:
          Bleeding Duration: 33%
      wvwModifiers:
        attributes:
          Bleeding Duration: 15%
      gw2id: 1337
      defaultEnabled: true
      temporaryBuff: false

    - id: bloodlust-on-crit
      text: Bloodlust
      subText: per hit (including non critical)
      minor: true
      amountData:
        label: 'hit/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Critical Chance: 100%}
      wvwModifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Critical Chance: 33%}
      gw2id: 1337
      defaultEnabled: false
      temporaryBuff: true

    - id: furious
      text: Furious
      subText: not automatically adjusted with crit chance!
      amountData:
        label: 'stacks'
        default: 21
        quantityEntered: 25
      modifiers:
        attributes:
          Condition Damage: [375, buff]
      wvwModifiers:
        attributes:
          Condition Damage: [250, buff]
      gw2id: 1346
      defaultEnabled: true
      temporaryBuff: true

- section: Defense
  id: 22
  items:

    - id: cull-the-weak
      text: Cull the Weak
      amountData:
        label: '% weakness'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, mult]
      gw2id: 1372
      defaultEnabled: true

    - id: merciless-hammer
      text: Merciless Hammer
      subText: 'against defiant foes'
      amountData:
        label: '% hammer/mace skills'
        default: 30 # approximated from october hammer qspb log
        quantityEntered: 30
      modifiers:
        damage:
          Outgoing Strike Damage: [20%, unknown]
      gw2id: 1367
      defaultEnabled: true

    - id: hardened-armor
      text: Hardened Armor
      subText: 100% resolution
      minor: true
      modifiers:
        damage:
          Damage Reduction: [10%, unknown]
      gw2id: 1380
      defaultEnabled: true

    - id: stalwart-strength
      text: Stalwart Strength
      amountData:
        label: '% stability uptime'
        default: 100 # approximated from october hammer qspb log
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, unknown]
      gw2id: 1708
      defaultEnabled: true

- section: Tactics
  id: 11
  items:

    - id: leg-specialist
      text: Leg Specialist
      amountData:
        label: '% impaired'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, mult]
      gw2id: 1469
      defaultEnabled: true

    - id: roaring-reveille
      text: Roaring Reveille
      modifiers:
        attributes:
          Concentration: [120, buff]
      wvwModifiers:
        attributes:
          Concentration: [60, buff]
      gw2id: 1471
      defaultEnabled: true
      temporaryBuff: false

    - id: empowered
      text: Empowered
      amountData:
        label: 'boons'
        default: 10
        quantityEntered: 1
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [1%, mult]
      gw2id: 1485
      defaultEnabled: true

    - id: warriors-cunning
      text: Warrior's Cunning
      amountData:
        label: '% above 80'
        default: 20
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [25%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, mult]
      gw2id: 1486
      defaultEnabled: true

    - id: vigorous-shouts
      text: Vigorous Shouts
      modifiers:
        conversion:
          Healing Power: {Power: 13%}
      gw2id: 1470
      defaultEnabled: true
      temporaryBuff: false

- section: Discipline
  id: 51
  items:

    - id: warriors-sprint
      text: Warrior's Sprint
      amountData:
        label: '% swiftness'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [3%, add]
      gw2id: 1413
      defaultEnabled: true

    - id: stalwart-focus
      text: Stalwart Focus
      modifiers:
        attributes:
          Outgoing Healing: 15%
      wvwModifiers:
        attributes:
          Outgoing Healing: 10%
      gw2id: 1381
      defaultEnabled: true
      temporaryBuff: false

    - id: axe-mastery-one
      text: Axe Mastery
      subText: base
      modifiers:
        attributes:
          Ferocity: [120, converted] # confirmed from bench hero panel
      gw2id: 1369
      defaultEnabled: true
      temporaryBuff: false

    - id: axe-mastery-axe
      text: Axe Mastery
      subText: with axe
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Ferocity: [120, converted] # confirmed from bench hero panel
      gw2id: 1369
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat # assume the user is on a dual-wield set

    - id: heightened-focus-2
      text: Heightened Focus
      subText: 2x
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Outgoing Healing: 10%
      gw2id: 1317
      defaultEnabled: false

    - id: heightened-focus-3
      text: Heightened Focus
      subText: 3x
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Outgoing Healing: 15%
      gw2id: 1317
      defaultEnabled: false

    - id: heightened-focus-4
      text: Heightened Focus
      subText: 4x
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Outgoing Healing: 20%
      gw2id: 1317
      defaultEnabled: false

- section: Berserker
  id: 18
  items:

    - id: smash-brawler-full
      text: Smash Brawler
      subText: 100% uptime # variable crit chance would be inaccurate
      modifiers:
        attributes:
          Critical Chance: 15%
      wvwModifiers:
        attributes:
          Critical Chance: 5%
      gw2id: 2049
      defaultEnabled: true
      temporaryBuff: true

    - id: blood-reaction-base
      text: Blood Reaction
      subText: base
      modifiers:
        conversion:
          Ferocity: {Precision: 12%}
          Condition Damage: {Power: 15%}
      wvwModifiers:
        conversion:
          Ferocity: {Precision: 5%}
          Condition Damage: {Power: 10%}
      gw2id: 2011
      defaultEnabled: true
      temporaryBuff: false

    - id: blood-reaction-with
      text: Blood Reaction
      subText: bonus
      amountData:
        label: '% berserk'
        default: 70
        quantityEntered: 100
      modifiers:
        conversion:
          Ferocity: {Precision: 12%}
          Condition Damage: {Power: 15%}
      wvwModifiers:
        conversion:
          Ferocity: {Precision: 5%}
          Condition Damage: {Power: 10%}
      gw2id: 2011
      defaultEnabled: true
      temporaryBuff: true

    - id: fatal-frenzy-power-condi
      text: Fatal Frenzy
      subText: power/condition damage
      amountData:
        label: '% berserk'
        default: 70
        quantityEntered: 100
      minor: true
      modifiers:
        attributes:
          Power: [300, buff]
          Condition Damage: [300, buff]
      wvwModifiers:
        attributes:
          Power: [150, buff]
          Condition Damage: [300, buff]
      gw2id: 2046
      defaultEnabled: true
      temporaryBuff: true

    - id: bloody-roar
      text: Bloody Roar
      amountData:
        label: '% berserk'
        default: 70
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 1928
      defaultEnabled: true

    - id: king-of-fires
      text: King of Fires
      modifiers:
        attributes:
          Burning Duration: 33%
      wvwModifiers:
        attributes:
          Burning Duration: 10%
      gw2id: 2038
      defaultEnabled: true
      temporaryBuff: false

- section: Spellbreaker
  id: 61
  items:

    - id: pure-strike-base
      text: Pure Strike
      subText: base
      modifiers:
        damage:
          Outgoing Critical Damage: [7.5%, mult]
      wvwModifiers:
        damage:
          Outgoing Critical Damage: [7%, mult]
      gw2id: 2107
      defaultEnabled: true

    - id: pure-strike-boonless
      text: Pure Strike
      subText: bonus
      amountData:
        label: '% boonless'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          # increases base version to 15%: 1.15/1.075 = 1.06976744186. see GitHub PR #612.
          Outgoing Critical Damage: [6.976744186%, mult]
      wvwModifiers:
        damage:
          # increases base version to 14%: 1.14/1.07 = 1.06542056075. see GitHub PR #612.
          Outgoing Critical Damage: [6.542056075%, mult]
      gw2id: 2107
      defaultEnabled: true

    - id: sun-and-moon-style
      text: Sun and Moon Style
      subText: mainhand dagger; boonless
      amountData:
        label: '% uptime'
        default: 50
        quantityEntered: 50
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 2095
      defaultEnabled: false

    - id: attackers-insight
      text: Attacker's Insight
      subText: be careful not to overcap
      minor: true
      amountData:
        label: 'stacks'
        default: 5
        quantityEntered: 5
        disableBlacklist: true
        # allowing dynamic critical chance per stack here
      modifiers:
        attributes:
          Ferocity: [250, buff]
          Power: [250, buff]
          Precision: [250, buff]
      wvwModifiers:
        attributes:
          Ferocity: [150, buff]
          Power: [150, buff]
          Precision: [150, buff]
      gw2id: 2130
      defaultEnabled: true
      temporaryBuff: true

    - id: magebane-tether
      text: Magebane Tether
      amountData:
        label: '% uptime'
        default: 75
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 2060
      defaultEnabled: true

- section: Bladesworn
  id: 68
  items:

    - id: fierce-as-fire
      text: Fierce as Fire
      amountData:
        label: 'stacks'
        default: 10
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [1%, add] # tested by ReMagic
      gw2id: 2302
      defaultEnabled: true

    - id: guns-and-glory
      text: Guns and Glory
      minor: true
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Ferocity: [250, unknown]
      gw2id: 2236
      defaultEnabled: true
      temporaryBuff: true
