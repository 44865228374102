- section: Skills
  items:

    - id: signet-of-spite
      text: Signet of Spite
      subText: passive
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [180, buff]
      gw2id: 10622
      temporaryBuff: activeOutOfCombat

    - id: signet-of-vampirism
      text: Signet of Vampirism
      subText: passive
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Siphon Base Coefficient: 151
          Siphon Coefficient: 0.022
      gw2id: 21762
      temporaryBuff: activeOutOfCombat

    - id: locust-swarm
      text: Locust Swarm
      amountData:
        label: '/10s'
        default: 0
        defaultInput: ???
        quantityEntered: 10
      modifiers:
        attributes:
          Siphon Base Coefficient: 1170
          Siphon Coefficient: 0.8
      gw2id: 10557
      temporaryBuff: true

    - id: soul-grasp
      text: Soul Grasp
      amountData:
        label: '/10s'
        default: 0
        defaultInput: ???
        quantityEntered: 10
      modifiers:
        attributes:
          Siphon Base Coefficient: 660
          Siphon Coefficient: 2
      wvwModifiers:
        attributes:
          Siphon Base Coefficient: 248
          Siphon Coefficient: 0.75
      gw2id: 55050
      temporaryBuff: true

- section: Spite
  id: 53
  items:

    - id: spiteful-talisman
      text: Spiteful Talisman
      amountData:
        label: '% boonless'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 914
      defaultEnabled: true

    - id: awaken-the-pain
      text: Awaken the Pain
      amountData:
        label: 'might'
        default: 25
        quantityEntered: 25
      modifiers:
        attributes:
          Power: [250, buff]
          Condition Damage: [-250, buff]
      gw2id: 829
      defaultEnabled: true
      temporaryBuff: true

    # - id: signets-of-suffering
    #   text: Signets of Suffering
    #   amountData:
    #     label: 'proc/s'
    #     default: 0
    #     defaultInput: ???
    #     quantityEntered: 1
    #   modifiers:
    #     attributes:
    #       Siphon Base Coefficient: ???
    #       Siphon Coefficient: ???
    #   wvwModifiers:
    #     attributes:
    #       Siphon Base Coefficient: ???
    #       Siphon Coefficient: ???
    #   gw2id: 909
    #   defaultEnabled: true

    - id: close-to-death
      text: Close to Death
      amountData:
        label: '% below 50'
        default: 50
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [20%, mult]
      gw2id: 853
      defaultEnabled: true

- section: Curses
  id: 39
  note: Be sure to change the condition coefficient values below when adding or removing traits without checkboxes that implement their full effects like lingering curse.
  items:

    - id: barbed-precision
      text: Barbed Precision
      subText: base
      minor: true
      modifiers:
        attributes:
          Bleeding Duration: 20%
      gw2id: 802
      defaultEnabled: true
      temporaryBuff: false

    - id: barbed-precision-on-crit
      text: Barbed Precision
      subText: per hit (including non critical)
      minor: true
      amountData:
        label: 'hit/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Critical Chance: 100%}
      wvwModifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Critical Chance: 33%}
      gw2id: 802
      defaultEnabled: false
      temporaryBuff: true

    - id: furious-demise
      text: Furious Demise
      minor: true
      modifiers:
        attributes:
          Precision: [180, buff] # unconverted (except by Target the Weak), tested by Marcus and Jezereal
      gw2id: 803
      defaultEnabled: true
      temporaryBuff: false

    - id: target-the-weak-base
      text: Target the Weak
      subText: base
      minor: true
      modifiers:
        conversion:
          Condition Damage: {Precision: 13%}
        attributes:
          Condition Damage: [23, buff] # conversion of 180 precision from Furious Demise; see https://wiki.guildwars2.com/wiki/Game_updates/2021-08-17#Necromancer
      gw2id: 810
      defaultEnabled: true
      temporaryBuff: false

    - id: target-the-weak
      text: Target the Weak
      amountData:
        label: 'conditions'
        default: 10
        quantityEntered: 10
        disableBlacklist: true
        # allowing dynamic critical chance per condition here
      minor: true
      modifiers:
        attributes:
          Critical Chance: 20%
      gw2id: 810
      defaultEnabled: true
      temporaryBuff: true

    - id: lingering-curse
      text: Lingering Curse
      subText: base only
      modifiers:
        attributes:
          Condition Damage: [200, buff]
      gw2id: 801
      defaultEnabled: true
      temporaryBuff: false

- section: Death Magic
  id: 2
  items:

    - id: carapace
      text: ''
      subText: toughness from carapace
      minor: true
      amountData:
        label: 'carapace'
        default: 6
        quantityEntered: 1
        disableBlacklist: true
        # allowing dynamic toughness per stack here
      modifiers:
        attributes:
          Toughness: [20, buff]
      defaultEnabled: true
      temporaryBuff: true

    - id: putrid-defense
      text: Putrid Defense
      modifiers:
        damage:
          Outgoing Poison Damage: [15%, mult] # unconfirmed
      gw2id: 857
      defaultEnabled: true

    - id: deadly-strength-6x
      text: Deadly Strength
      amountData:
        label: 'carapace'
        default: 6
        quantityEntered: 1
      modifiers:
        attributes:
          Power: [10, buff]
          Condition Damage: [10, buff] # unverified
      gw2id: 855
      defaultEnabled: true
      temporaryBuff: true

- section: Blood Magic
  id: 19
  items:

    # includes the value of all 5 vampiric mark stacks
    - id: blood-bond
      text: Blood Bond
      amountData:
        label: '/10s'
        default: 0.75 # 13.3 second interval seems reasonable with alacrity?
        quantityEntered: 10
      modifiers:
        attributes:
          Siphon Base Coefficient: 1235
          Siphon Coefficient: 0.42
      gw2id: 1876
      defaultEnabled: true
      temporaryBuff: true

    - id: vampiric
      text: Vampiric
      subText: self only
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 38
          Siphon Coefficient: 0.003
      gw2id: 783
      minor: true
      defaultEnabled: true
      temporaryBuff: true

    - id: life-from-death
      text: Life from Death
      modifiers:
        attributes:
          Outgoing Healing: 10%
      gw2id: 789
      defaultEnabled: true
      temporaryBuff: false

    - id: vampiric-presence
      text: Vampiric Presence
      subText: not in shroud
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 65
          Siphon Coefficient: 0.0333
      gw2id: 1844
      defaultEnabled: true
      temporaryBuff: true

    - id: vampiric-presence-shroud
      text: Vampiric Presence
      subText: in shroud
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 129
          Siphon Coefficient: 0.0666
      gw2id: 1844
      defaultEnabled: true
      temporaryBuff: true

- section: Soul Reaping
  id: 50
  items:

    # affects lifesteal
    # https://discord.com/channels/380901000200060929/1017105862357176341/1017167619478003792
    - id: soul-barbs
      text: Soul Barbs
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing All Damage: [10%, add]
      gw2id: 894
      defaultEnabled: true

    - id: vital-persistence
      text: Vital Persistence
      modifiers:
        attributes:
          Vitality: [180, converted]
      gw2id: 861
      defaultEnabled: true
      temporaryBuff: false

    - id: death-perception
      text: Death Perception
      modifiers:
        attributes:
          Critical Chance: 15%
        damage:
          Outgoing Alternative Critical Damage: [15%, unknown]
      gw2id: 893
      defaultEnabled: true
      temporaryBuff: false

- section: Scourge
  id: 60
  note: Be sure to change the condition coefficient values below when adding or removing traits without checkboxes that implement their full effects like demonic lore.
  items:

    - id: fell-beacon
      text: Fell Beacon
      modifiers:
        damage:
          Outgoing Burning Damage: [10%, mult] # unconfirmed
        conversion:
          Expertise: {Condition Damage: 7%}
      wvwModifiers:
        damage:
          Outgoing Burning Damage: [10%, mult] # unconfirmed
        conversion:
          Expertise: {Condition Damage: 4%}
      gw2id: 2074
      defaultEnabled: true
      temporaryBuff: false

    - id: sand-sage-active
      text: Sand Sage
      subText: with active shade
      minor: true
      modifiers:
        attributes:
          Concentration: [225, converted]
          Expertise: [225, converted]
      wvwModifiers:
        attributes:
          Concentration: [150, converted]
          Expertise: [150, converted]
      gw2id: 2121
      defaultEnabled: true
      temporaryBuff: true

    - id: sand-sage-1x-old
      text: Sand Sage
      subText: 1x (old)
      minor: true
      modifiers:
        attributes:
          Concentration: [75, converted]
          Expertise: [75, converted]
      wvwModifiers:
        attributes:
          Concentration: [45, converted]
          Expertise: [45, converted]
      gw2id: 2121
      defaultEnabled: false
      temporaryBuff: true

    - id: sand-sage-2x-old
      text: Sand Sage
      subText: 2x (old)
      minor: true
      modifiers:
        attributes:
          Concentration: [150, converted]
          Expertise: [150, converted]
      wvwModifiers:
        attributes:
          Concentration: [90, converted]
          Expertise: [90, converted]
      gw2id: 2121
      defaultEnabled: false
      temporaryBuff: true

    - id: sand-sage-3x-old
      text: Sand Sage
      subText: 3x (old)
      minor: true
      modifiers:
        attributes:
          Concentration: [225, converted]
          Expertise: [225, converted]
      wvwModifiers:
        attributes:
          Concentration: [135, converted]
          Expertise: [135, converted]
      gw2id: 2121
      defaultEnabled: false
      temporaryBuff: true

    - id: blood-as-sand-active
      text: Blood as Sand
      subText: with active sand shade
      minor: true
      modifiers:
        damage:
          Damage Reduction: [15%, unknown]
      wvwModifiers:
        damage:
          Damage Reduction: [7%, unknown]
      gw2id: 2096
      defaultEnabled: true

    - id: blood-as-sand-1x-old
      text: Blood as Sand
      subText: 1x (old)
      minor: true
      modifiers:
        damage:
          Damage Reduction: [5%, unknown]
      wvwModifiers:
        damage:
          Damage Reduction: [3%, unknown]
      gw2id: 2096
      defaultEnabled: false

    - id: blood-as-sand-2x-old
      text: Blood as Sand
      subText: 2x (old)
      minor: true
      modifiers:
        damage:
          Damage Reduction: [10%, unknown]
      wvwModifiers:
        damage:
          Damage Reduction: [6%, unknown]
      gw2id: 2096
      defaultEnabled: false

    - id: blood-as-sand-3x-old
      text: Blood as Sand
      subText: 3x (old)
      minor: true
      modifiers:
        damage:
          Damage Reduction: [15%, unknown]
      wvwModifiers:
        damage:
          Damage Reduction: [9%, unknown]
      gw2id: 2096
      defaultEnabled: false

    - id: demonic-lore
      text: Demonic Lore
      subText: torment only
      modifiers:
        damage:
          Outgoing Torment Damage: [33%, mult] # unconfirmed
      wvwModifiers:
        damage:
          Outgoing Torment Damage: [33%, mult] # unconfirmed
      gw2id: 2164
      defaultEnabled: true

- section: Reaper
  id: 34
  items:

    - id: augury-of-death
      text: Augury of Death
      subText: melee
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 344
          Siphon Coefficient: 0.025
      gw2id: 1974
      defaultEnabled: true
      temporaryBuff: true

    - id: augury-of-death-ranged
      text: Augury of Death
      subText: not melee
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 172
          Siphon Coefficient: 0.0125
      gw2id: 1974
      defaultEnabled: true
      temporaryBuff: true

    - id: soul-eater
      text: Soul Eater
      amountData:
        label: '% melee'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 1969
      defaultEnabled: true

    - id: decimate-defenses-25x
      text: Decimate Defenses
      subText: 25x
      modifiers:
        attributes:
          Critical Chance: 50%
      gw2id: 2031
      defaultEnabled: true
      temporaryBuff: true

    - id: cold-shoulder
      text: Cold Shoulder
      amountData:
        label: '% chill'
        default: 100
        quantityEntered: 100
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 2018
      defaultEnabled: true

    - id: reapers-onslaught
      text: Reaper's Onslaught
      modifiers:
        attributes:
          Alternative Ferocity: [300, buff]
      gw2id: 2021
      defaultEnabled: true
      temporaryBuff: false

- section: Harbinger
  id: 64
  items:

    - id: wicked-corruption-base
      text: Wicked Corruption
      subText: base
      modifiers:
        damage:
          Outgoing Critical Damage: [12.5%, unknown]
      wvwModifiers:
        damage:
          Outgoing Critical Damage: [10%, unknown]
      gw2id: 2188
      defaultEnabled: true

    - id: wicked-corruption
      text: Wicked Corruption
      amountData:
        label: 'blight stacks'
        default: 11.6
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [1%, add] # tested by ReMagic
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [0.5%, add] # tested by ReMagic
      gw2id: 2188
      defaultEnabled: true

    - id: septic-corruption
      text: Septic Corruption
      amountData:
        label: 'blight stacks'
        default: 11.6
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Condition Damage: [0.25%, add] # tested by ReMagic
      wvwModifiers:
        damage:
          Outgoing Condition Damage: [0.5%, add]
      gw2id: 2185
      defaultEnabled: true

    # using subtext on this to ensure it is a checkbox
    - id: alchemic-vigor
      text: Alchemic Vigor
      subText: 'fixed'
      minor: true
      modifiers:
        attributes:
          Vitality: [240, converted]
      gw2id: 2186
      defaultEnabled: true
      temporaryBuff: false

    - id: alchemic-vigor-bugged
      text: Alchemic Vigor
      subText: 'bug: converted like 360 vitality'
      minor: true
      modifiers:
        attributes:
          # inexplicable EoD launch nerf; tested by Jezereal + Marcus
          # trait gives 240 vitality in hero panel and health amount, but 360 is converted by
          # master traits and magnanimous utility
          Vitality: [360, converted, -120, buff]
      gw2id: 2186
      defaultEnabled: false
      temporaryBuff: false

    - id: implacable-foe
      text: Implacable Foe
      modifiers:
        conversion:
          Ferocity: {Vitality: 13%}
      gw2id: 2192
      defaultEnabled: true
      temporaryBuff: false

    - id: twisted-medicine
      text: Twisted Medicine
      modifiers:
        conversion:
          Concentration: {Vitality: 13%}
      gw2id: 2220
      defaultEnabled: true
      temporaryBuff: false

    - id: dark-gunslinger
      text: Dark Gunslinger
      modifiers:
        conversion:
          Expertise: {Vitality: 13%}
      gw2id: 2209
      defaultEnabled: true
      temporaryBuff: false

    - id: cascading-corruption
      text: Cascading Corruption
      subText: power bonus only
      modifiers:
        attributes:
          Power: [240, converted] # tested by Jezereal
      gw2id: 2218
      defaultEnabled: true
      temporaryBuff: false

    - id: doom-approaches
      text: Doom Approaches
      modifiers:
        attributes:
          Condition Damage: [240, converted] # tested by Jezereal
      gw2id: 2203
      defaultEnabled: true
      temporaryBuff: false
