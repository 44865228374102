GraphQL ID: presetDistribution
list:
  # button not shown; used for no-op templates
  - name: None
    value: >-
      {
        "values2": { "Power": 0, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    noCreditOkay: true

  - name: 100% Power
    value: >-
      {
        "values2": { "Power": 3000, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    noCreditOkay: true

  - name: No Simulation
    hidden: true
    value: >-
      {
        "values2": { "Power": 1, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    noCreditOkay: true

  # Uses air/hydro sigils, somewhat taken into consideration by giving air a
  # proc rate high enough to match air+hydro damage
  - name: Power Berserker
    profession: Berserker
    value: >-
      {
        "values2": { "Power": 3871, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Linsi
        url: https://www.youtube.com/watch?v=nI-Y6GsZvUQ
        log: https://dps.report/fLGV-20240202-220024_golem

  # Uses air/hydro sigils, somewhat taken into consideration by giving air a
  # proc rate high enough to match air+hydro damage
  - name: Power Quickness Berserker
    profession: Berserker
    value: >-
      {
        "values2": { "Power": 3829, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Linsi
        url: https://www.youtube.com/watch?v=HzFEwIXT5Nw
        log: https://dps.report/LLcD-20240203-030534_golem

  - name: Power Spellbreaker GS
    profession: Spellbreaker
    value: >-
      {
        "values2": { "Power": 2909, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'DaniDomum'
        url: https://www.youtube.com/watch?v=Y47L5J18cS8
        log: https://dps.report/Be28-20221130-122045_golem

  - name: Power Spellbreaker Hammer
    profession: Spellbreaker
    value: >-
      {
        "values2": { "Power": 2329, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Baseraver
        url: https://www.youtube.com/watch?v=nmG8osqWv_s
        log: https://dps.report/MloE-20240205-165918_golem

  # includes sigils
  - name: Condi Quickness Bers
    profession: Berserker
    value: >-
      {
        "values2": { "Power": 2438, "Power2": 0, "Burning": 8.82, "Bleeding": 14.13, "Poisoned": 2.1, "Torment": 3.64, "Confusion": 0 }
      }
    credit:
      - author: 'Saint Mighty'
        url: https://www.youtube.com/watch?v=hHBBZwsdN7k
        log: https://dps.report/tNb1-20231014-165434_golem

  # includes sigils
  - name: Condi Berserker
    profession: Berserker
    value: >-
      {
        "values2": { "Power": 2470, "Power2": 0, "Burning": 8.29, "Bleeding": 13.67, "Poisoned": 2.15, "Torment": 5.11, "Confusion": 0 }
      }
    credit:
      - author: 'Costa Primo'
        url: https://www.youtube.com/watch?v=Cg52M5WGU5o
        log: https://dps.report/RzZh-20231014-102529_golem

  - name: Power Alacrity Bladesworn
    profession: Bladesworn
    value: >-
      {
        "values2": { "Power": 3916, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Evo
        url: https://www.youtube.com/watch?v=I7N_w15FL94
        log: https://dps.report/Jigl-20230717-221233_golem

  - name: DPS Bladesworn Tactics
    profession: Bladesworn
    value: >-
      {
        "values2": { "Power": 4548, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Evo
        url: https://www.youtube.com/watch?v=0uJWLIKmN_c
        log: https://dps.report/Kan2-20230824-061419_golem

  - name: DH Radiance
    profession: Dragonhunter
    value: >-
      {
        "values2": { "Power": 4085, "Power2": 0, "Burning": 2.59, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Shavy
        url: https://www.youtube.com/watch?v=7-nqp2Ths3s
        log: https://dps.report/GQ29-20230504-221813_golem

  - name: DH Virtues
    profession: Dragonhunter
    value: >-
      {
        "values2": { "Power": 3967, "Power2": 0, "Burning": 2.8, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Derm
        url: https://www.youtube.com/watch?v=S3qr1eATkAU
        log: https://dps.report/nQ3s-20231011-073653_golem


  # https://www.youtube.com/watch?v=G3CngDG-cMg
  # 12 boons
  - name: Core Guardian
    profession: Guardian
    value: >-
      {
        "values2": { "Power": 3340, "Power2": 0, "Burning": 1.1, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    noCreditOkay: true

  # (this is actually an old core guardian distribution iirc)
  - name: Power Quickbrand (approx.)
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 3390, "Power2": 0, "Burning": 1.1, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    noCreditOkay: true

  - name: Condi Willbender
    profession: Willbender
    value: >-
      {
        "values2": { "Power": 2043, "Power2": 0, "Burning": 16.4, "Bleeding": 6.46, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Incera
        url: https://www.youtube.com/watch?v=BrQ8MZhnvRI
        log: https://dps.report/nqi8-20240420-175827_golem

  - name: Condi Willbender Sword
    profession: Willbender
    value: >-
      {
        "values2": { "Power": 2871, "Power2": 0, "Burning": 14.05, "Bleeding": 0, "Poisoned": 0, "Torment": 0.08, "Confusion": 0 }
      }
    credit:
      - author: 'Support Hero'
        url: https://www.youtube.com/watch?v=1_GEZ8Wq2PM
        log: https://dps.report/W6No-20231004-182316_golem

  # 12 boons
  # viper glove boot ring ear ear back, rest grieving, flame legion, fishy
  # NO JADE BOT (in arc log, probably - seems bugged)
  - name: Condi Willbender GS
    profession: Willbender
    value: >-
      {
        "values2": { "Power": 3010, "Power2": 0, "Burning": 13.38, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: '[CnD] DaedDee'
        url: https://www.youtube.com/watch?v=mCOEGlJkRJ0
        log: https://dps.report/z2Zl-20220316-172733_golem

  - name: Condi Firebrand
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 1784, "Power2": 0, "Burning": 14.49, "Bleeding": 12.08, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Iskarel
        url: https://www.youtube.com/watch?v=9BT5TBoaWEk
        log: https://dps.report/bXp7-20240321-001338_golem

  - name: Condi Firebrand (No Allies)
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 1775, "Power2": 0, "Burning": 13.39, "Bleeding": 12.05, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Iskarel
        url: https://www.youtube.com/watch?v=9BT5TBoaWEk
        log: https://dps.report/CdPR-20240321-151620_golem

  - name: Condi Quickbrand (WT, Allies)
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 1397, "Power2": 0, "Burning": 14.23, "Bleeding": 6.07, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Iskarel
        url: https://www.youtube.com/watch?v=jv6BRBFb_H8
        log: https://dps.report/manG-20240324-193028_golem

  - name: Condi Quickbrand (WT, No Allies)
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 1391, "Power2": 0, "Burning": 13.51, "Bleeding": 5.83, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Iskarel
        url: https://www.youtube.com/watch?v=jv6BRBFb_H8
        log: https://dps.report/OeKr-20240324-194950_golem

  # Solo log adjusted upwards to match with-allies dps (no log for the with allies bench)
  - name: Condi Quickbrand (LL, Allies, Approx.)
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 2023, "Power2": 0, "Burning": 16.65, "Bleeding": 3.51, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Support Hero'
        url: https://www.youtube.com/watch?v=zGr5pn7jn2A
        log: https://dps.report/GP0v-20230228-174716_golem

  - name: Condi Quickbrand (LL, No Allies)
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 2023, "Power2": 0, "Burning": 13.95, "Bleeding": 3.34, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Support Hero'
        url: https://www.youtube.com/watch?v=zGr5pn7jn2A
        log: https://dps.report/GP0v-20230228-174716_golem

  # - name: Hybrid FB (Virtues, no allies)
  #   profession: Firebrand
  #   value: >-
  #     {
  #       "values2": { "Power": 1942, "Power2": 0, "Burning": 12.1, "Bleeding": 3.0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
  #     }

  # +1.7 burning for no allies/with allies split on 5 page seems to hold up well

  - name: Hybrid FB Virtues
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 1864, "Power2": 0, "Burning": 13.8, "Bleeding": 3.0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    noCreditOkay: true

  - name: Hybrid FB (Honor, No Allies)
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 2203, "Power2": 0, "Burning": 9.05, "Bleeding": 16.22, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Ostro
        url: https://www.youtube.com/watch?v=JMOfyIn0A5g
        log: https://dps.report/nc8C-20230701-014927_golem

  - name: Hybrid FB (Honor, Allies)
    profession: Firebrand
    value: >-
      {
        "values2": { "Power": 2014, "Power2": 0, "Burning": 10.92, "Bleeding": 16.16, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Ostro
        url: https://www.youtube.com/watch?v=JMOfyIn0A5g
        log: https://dps.report/qV8u-20230701-014606_golem

  # https://www.youtube.com/watch?v=-OiH1BLuJpo
  # https://dps.report/QdzV-35062rwb_golem
  # RHS
  - name: Power Willbender Radiance
    profession: Willbender
    value: >-
      {
        "values2": { "Power": 3289, "Power2": 0, "Burning": 2.9, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    noCreditOkay: true

  - name: Power Willbender Virtues
    profession: Willbender
    value: >-
      {
        "values2": { "Power": 4387, "Power2": 0, "Burning": 5.59, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Andy
        url: https://www.youtube.com/watch?v=282m_ydQUdY
        log: https://dps.report/Ixw2-20230502-200305_golem

  # 12 boons, celefood toxiccrystal, 14%
  - name: Condi Alacrity Willbender
    profession: Willbender
    value: >-
      {
        "values2": { "Power": 2711, "Power2": 0, "Burning": 9.8, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Support Hero'
        url: https://www.youtube.com/watch?v=cu8N1ZDKmzU
        log: https://dps.report/rEUF-20220329-113027_golem

  # https://www.youtube.com/watch?v=uPQfoQ0sUYU
  # https://www.youtube.com/watch?v=uPQfoQ0sUYU
  # 12 boons, thief accuracy, 14%
  - name: Power Alacrity Willbender
    profession: Willbender
    value: >-
      {
        "values2": { "Power": 3506, "Power2": 0, "Burning": 2.49, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    noCreditOkay: true

  - name: Power Weaver (BTTH, small)
    profession: Weaver
    value: >-
      {
        "values2": { "Power": 3844, "Power2": 0, "Burning": 6.58, "Bleeding": 4.02, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Ryan'
        url: https://www.youtube.com/watch?v=j1hJg6g1i1E
        log: https://dps.report/SbRf-20231003-025202_golem

  # no jade bot
  - name: Condi Weaver Sword
    profession: Weaver
    value: >-
      {
        "values2": { "Power": 2626, "Power2": 0, "Burning": 11.78, "Bleeding": 14.92, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: '[SC] Roul'
        url: https://www.youtube.com/watch?v=H5ZyMQln6hw
        log: https://dps.report/Bb0l-20210609-103359_golem

  # no jade bot
  - name: Condi Weaver (Dagger)
    profession: Weaver
    value: >-
      {
        "values2": { "Power": 2091, "Power2": 0, "Burning": 12.01, "Bleeding": 17.55, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Ryan'
        url: https://www.youtube.com/watch?v=5XgoudKAKRw
        log: https://dps.report/g1E9-20210609-022715_golem

  - name: Condi Weaver Staff (Large)
    profession: Weaver
    value: >-
      {
        "values2": { "Power": 3096, "Power2": 0, "Burning": 10.35, "Bleeding": 16.51, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Fennec'
        url: https://www.youtube.com/watch?v=4cj0BEcXCpc
        log: https://dps.report/5ShG-20230714-032530_golem

  # Without eles (distribution still comparable, lower rng)
  - name: Condi Weaver Scepter
    profession: Weaver
    value: >-
      {
        "values2": { "Power": 2226, "Power2": 0, "Burning": 13.49, "Bleeding": 12.29, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Ryan
        url: https://www.youtube.com/watch?v=PJZrqru4f6I
        log: https://dps.report/JIUx-20231008-232724_golem

  - name: Hybrid Weaver
    profession: Weaver
    value: >-
      {
        "values2": { "Power": 3328, "Power2": 0, "Burning": 11.76, "Bleeding": 1.88, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: '[SC] Trace'
        url: https://www.youtube.com/watch?v=sU5-g4wBsgw
        log: https://dps.report/4kbg-20230312-075239_golem

  - name: Alacrity Hybrid Tempest
    profession: Tempest
    value: >-
      {
        "values2": { "Power": 2930, "Power2": 0, "Burning": 11.56, "Bleeding": 11.96, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Dánmander'
        url: https://www.youtube.com/watch?v=WOrKFRobSiU
        log: https://dps.report/RMsk-20231213-174648_golem

  - name: Condi Tempest
    profession: Tempest
    value: >-
      {
        "values2": { "Power": 2506, "Power2": 0, "Burning": 10.73, "Bleeding": 16.73, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Dánmander'
        url: https://www.youtube.com/watch?v=UI_DqbbBhWg
        log: https://dps.report/JJvS-20231203-145912_golem

  # gear is missing one infusion (and not crit capped?)
  - name: Power Tempest
    profession: Tempest
    value: >-
      {
        "values2": { "Power": 4778, "Power2": 0, "Burning": 4.37, "Bleeding": 4.22, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: '[SC] Roul'
        url: https://www.youtube.com/watch?v=4i5TFegDNXg
        log: https://dps.report/6Kzq-20221201-114817_golem

  - name: Power Catalyst
    profession: Catalyst
    value: >-
      {
        "values2": { "Power": 3294, "Power2": 0, "Burning": 4.07, "Bleeding": 5.49, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: '[SC] Roul'
        url: https://www.youtube.com/watch?v=a405zazuMZw
        log: https://dps.report/9pYO-20221213-215347_golem

  - name: Power Quickness Catalyst
    profession: Catalyst
    value: >-
      {
        "values2": { "Power": 3417, "Power2": 0, "Burning": 3.65, "Bleeding": 6.71, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Cody
        url: https://www.youtube.com/watch?v=92XCBnuU5jM
        log: https://dps.report/MtKD-20221221-124558_golem

  - name: Alacrity Renegade
    profession: Renegade
    value: >-
      {
        "values2": { "Power": 3532, "Power2": 0, "Burning": 0.78, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Umbra'
        url: https://www.youtube.com/watch?v=s1OXN6HRLTo
        log: https://dps.report/8LBy-20230515-192958_golem

  # add razorclaw casts every 20sec to simulate allies
  # 4 players * 10 pulses * 2 seconds / 20 sec = 4.00 avg bleed
  - name: Condi Alac Invocation
    profession: Renegade
    value: >-
      {
        "values2": { "Power": 1903, "Power2": 0, "Burning": 4.14, "Bleeding": 13.37, "Poisoned": 4, "Torment": 16.09, "Confusion": 0 }
      }
    credit:
      - author: Umbra
        url: https://www.youtube.com/watch?v=uCI1Xu63SmM
        log: https://dps.report/ervZ-20231003-013459_golem

  - name: Condi Alac Invocation (no allies)
    profession: Renegade
    value: >-
      {
        "values2": { "Power": 1903, "Power2": 0, "Burning": 4.14, "Bleeding": 9.37, "Poisoned": 4, "Torment": 16.09, "Confusion": 0 }
      }
    credit:
      - author: Umbra
        url: https://www.youtube.com/watch?v=uCI1Xu63SmM
        log: https://dps.report/ervZ-20231003-013459_golem

  # add razorclaw casts every 20sec to simulate allies
  # 4 players * 10 pulses * 2 seconds / 20 sec = 4.00 avg bleed
  - name: Condi Renegade Devastation
    profession: Renegade
    value: >-
      {
        "values2": { "Power": 2092, "Power2": 0, "Burning": 4.21, "Bleeding": 14.13, "Poisoned": 2.23, "Torment": 16.34, "Confusion": 0 }
      }
    credit:
      - author: '[Inn] Xorxy'
        url: https://www.youtube.com/watch?v=PvE-x43QrHo
        log: https://dps.report/kByf-20220314-131547_golem

  # AP, full viper, asparagus food
  - name: Condi Renegade Devastation (no allies)
    profession: Renegade
    value: >-
      {
        "values2": { "Power": 2092, "Power2": 0, "Burning": 4.21, "Bleeding": 10.13, "Poisoned": 2.23, "Torment": 16.34, "Confusion": 0 }
      }
    credit:
      - author: '[Inn] Xorxy'
        url: https://www.youtube.com/watch?v=PvE-x43QrHo
        log: https://dps.report/kByf-20220314-131547_golem

  # add razorclaw casts every 20sec to simulate allies
  # 4 players * 10 pulses * 2 seconds / 20 sec = 4.00 avg bleed
  - name: Condi Renegade Invocation
    profession: Renegade
    value: >-
      {
        "values2": { "Power": 2011, "Power2": 0, "Burning": 4.48, "Bleeding": 14.48, "Poisoned": 4.07, "Torment": 18.32, "Confusion": 0 }
      }
    credit:
      - author: '[InS] Laoshi'
        url: https://www.youtube.com/watch?v=9wOyiE2Bvts
        log: https://dps.report/EeYd-20221008-182313_golem

  - name: Condi Renegade Invocation (no allies)
    profession: Renegade
    value: >-
      {
        "values2": { "Power": 2011, "Power2": 0, "Burning": 4.48, "Bleeding": 10.48, "Poisoned": 4.07, "Torment": 18.32, "Confusion": 0 }
      }
    credit:
      - author: '[InS] Laoshi'
        url: https://www.youtube.com/watch?v=9wOyiE2Bvts
        log: https://dps.report/EeYd-20221008-182313_golem

  # not including ally lifesteal
  # todo: convert 122dps to self lifesteal
  - name: Power Quickness Herald
    profession: Herald
    value: >-
      {
        "values2": { "Power": 3056, "Power2": 0, "Burning": 0.72, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Umbra
        url: https://www.youtube.com/watch?v=uvMQy0C9fo0
        log: https://dps.report/gpDG-20231003-204833_golem

  # not including ally lifesteal
  # todo: convert 268dps to self lifesteal
  - name: Power Herald FP
    profession: Herald
    value: >-
      {
        "values2": { "Power": 2838, "Power2": 0, "Burning": 1.43, "Bleeding": 0, "Poisoned": 0, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Umbra'
        url: https://www.youtube.com/watch?v=DJtoo1abq9U
        log: https://dps.report/rW1Q-20221202-011416_golem

  - name: Condi Quickness Herald
    profession: Herald
    value: >-
      {
        "values2": { "Power": 2148, "Power2": 0, "Burning": 4.75, "Bleeding": 6.5, "Poisoned": 4.9, "Torment": 15.63, "Confusion": 0 }
      }
    credit:
      - author: Botinator
        url: https://www.youtube.com/watch?v=PT0N2cvLPcQ
        log: https://dps.report/azH7-20240601-133340_golem

  # force air force air
  - name: DPS Vindicator
    profession: Vindicator
    value: >-
      {
        "values2": { "Power": 3673, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0, "Torment": 2.96, "Confusion": 0 }
      }
    credit:
      - author: beng / L3m0n
        url: https://www.youtube.com/watch?v=tu56q1WZlOU
        log: https://dps.report/RlrB-20240222-163421_golem

  - name: Condi Quickness Untamed
    profession: Untamed
    value: >-
      {
        "values2": { "Power": 2104, "Power2": 0, "Burning": 3.46, "Bleeding": 21.84, "Poisoned": 13.23, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Tiramichu
        url: https://www.youtube.com/watch?v=svoHI_hOhds
        log: https://dps.report/r4Vc-20231014-151702_golem

  - name: Power Soulbeast
    profession: Soulbeast
    value: >-
      {
        "values2": { "Power": 3480, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0.39, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: '[InS] Laoshi'
        url: https://www.youtube.com/watch?v=3YsQTHBCGEo
        log: https://dps.report/KXbi-20231003-200539_golem

  # https://www.youtube.com/watch?v=-bipnEirEGA
  # https://dps.report/JBAr-20210620-110933_golem
  # no jade bot, rendang
  #
  # 698 dps from OWP (which is ~234 power coefficient)
  # 57 hits in 110s, 0.518 hit/s
  # no sic em coefficient: .63 * 690.5 = 435.0 * hitrate
  # 0.518 hit/s * 435.0 = 225
  # (does not match well because of higher than average TaV on OWP, but whatever)
  - name: Condi Slb (D/T SB)
    profession: Soulbeast
    value: >-
      {
        "values2": { "Power": 1366, "Power2": 0, "Burning": 2.42, "Bleeding": 21.7, "Poisoned": 16.26, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Snow Crows

  # https://www.youtube.com/watch?v=rr-gJuN3ECA
  # https://dps.report/zzE6-20210514-155136_golem
  # no jade bot, rendang
  #
  # 644 dps from OWP (which is ~216 power coefficient)
  # 56 hits in 110s, 0.51 hit/s
  # no sic em coefficient: .63 * 690.5 = 435.0 * hitrate
  # 0.51 hit/s * 435.0 = 222
  # (does not match well because of higher than average TaV on OWP, but whatever)
  - name: Condi Slb (D/T A/D)
    profession: Soulbeast
    value: >-
      {
        "values2": { "Power": 1442, "Power2": 0, "Burning": 3.54, "Bleeding": 19.95, "Poisoned": 13.86, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Snow Crows

  # Approximated with full solo hits/cast of vulture stance (36)
  # Note: solo number, not with allies
  - name: Condi Slb D/D SB
    profession: Soulbeast
    value: >-
      {
        "values2": { "Power": 1729, "Power2": 0, "Burning": 0, "Bleeding": 23.59, "Poisoned": 12.53, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Pip
        url: https://www.youtube.com/watch?v=nAJhBrLKLvE
        log: https://dps.report/4Iwe-20231003-183528_golem

  # - name: Hybrid Slb OS (A/T D/A)
  #   profession: Soulbeast
  #   value: >-
  #     {
  #       "values2": { "Power": 2382, "Power2": 0, "Burning": 2.25, "Bleeding": 23.11, "Poisoned": 3.14, "Torment": 0.05, "Confusion": 0 }
  #     }
  #   credit:
  #     - author: Umbra
  #       url: https://www.youtube.com/watch?v=SgCNpLWO-KA
  #       log: https://dps.report/qHdT-20220712-170120_golem

  - name: Hybrid Slb A/T D/A (LotP)
    profession: Soulbeast
    value: >-
      {
        "values2": { "Power": 2531, "Power2": 0, "Burning": 2.12, "Bleeding": 19.45, "Poisoned": 2.56, "Torment": 0.04, "Confusion": 0 }
      }
    credit:
      - author: Pip
        url: https://www.youtube.com/watch?v=JuZyk0XhypM
        log: https://dps.report/aZbB-20230628-221125_golem

  # im sorry
  # koi cake
  # - name: Pure Shortbow Slb BM
  #   profession: Soulbeast
  #   value: >-
  #     {
  #       "values2": { "Power": 1661, "Power2": 0, "Burning": 0.54, "Bleeding": 25.48, "Poisoned": 11.45, "Torment": 0, "Confusion": 0 }
  #     }
  #   credit:
  #     - author: 'Talonflame'
  #       url: https://www.youtube.com/watch?v=70F-uEOb-Bg
  #       log: https://dps.report/Oq8d-20210609-135312_golem

  - name: Condi Druid
    profession: Druid
    value: >-
      {
        "values2": { "Power": 1594, "Power2": 0, "Burning": 4.33, "Bleeding": 30.01, "Poisoned": 15.04, "Torment": 0.05, "Confusion": 0 }
      }
    credit:
      - author: '[CnD] Tipcat'
        url: https://www.youtube.com/watch?v=II1pfW01vMc
        log: https://dps.report/T27X-20230701-154750_golem

  - name: Power Holo PBM
    profession: Holosmith
    value: >-
      {
        "values2": { "Power": 3974, "Power2": 0, "Burning": 5.27, "Bleeding": 5.1, "Poisoned": 2.95, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: '[SC] Fallen'
        url: https://www.youtube.com/watch?v=v6AARzsY6iA
        log: https://dps.report/P7Qz-20231003-143924_golem

  - name: Power Holo ECSU
    profession: Holosmith
    value: >-
      {
        "values2": { "Power": 3890, "Power2": 0, "Burning": 5.41, "Bleeding": 9.82, "Poisoned": 2.39, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: Cody
        url: https://www.youtube.com/watch?v=F6A09H0W0B4
        log: https://dps.report/j9eE-20240324-171603_golem

  - name: Condi Holo
    profession: Holosmith
    value: >-
      {
        "values2": { "Power": 2915, "Power2": 0, "Burning": 13.13, "Bleeding": 10.95, "Poisoned": 7.64, "Torment": 0.06, "Confusion": 4.28 }
      }
    credit:
      - author: beng / L3m0n
        url: https://www.youtube.com/watch?v=jCF4hW8Ziz0
        log: https://dps.report/mbPx-20231009-150450_golem

  # has lifesteal food; subtracy 127 power dps
  # mech does burning (???) - import tool breaks
  - name: Power Alacrity Mechanist (inaccurate)
    profession: Mechanist
    value: >-
      {
        "values2": { "Power": 2745, "Power2": 0, "Burning": 4.38, "Bleeding": 5.45, "Poisoned": 3.45, "Torment": 0, "Confusion": 10.45 }
      }
    credit:
      - author: '[CnD] Xyonon'
        url: https://www.youtube.com/watch?v=xjCNAyzVmQM
        log: https://dps.report/PRXW-20220416-225625_golem

  - name: Condi Mechanist J-Drive (approx.)
    profession: Mechanist
    value: >-
      {
        "values2": { "Power": 3622, "Power2": 0, "Burning": 6.17, "Bleeding": 18.34, "Poisoned": 9.03, "Torment": 0, "Confusion": 3.83 }
      }
    credit:
      - author: '[NA] Crone'
        url: https://www.youtube.com/watch?v=o3cmPNH620s
        log: https://dps.report/Vh6i-20240220-101735_golem

  # icicle
  - name: Condi Mechanist Jade Dynamo Pistol (approx.)
    profession: Mechanist
    value: >-
      {
        "values2": { "Power": 3566, "Power2": 0, "Burning": 7.42, "Bleeding": 17.54, "Poisoned": 10.05, "Torment": 0, "Confusion": 9.18 }
      }
    credit:
      - author: '[CnD] Lunar'
        url: https://www.youtube.com/watch?v=URNB2JkmtG0
        log: https://dps.report/8GVP-20220308-005706_golem

  - name: Condi Alac Mechanist (inaccurate)
    profession: Mechanist
    value: >-
      {
        "values2": { "Power": 3124, "Power2": 0, "Burning": 8.81, "Bleeding": 9.98, "Poisoned": 9.25, "Torment": 0, "Confusion": 5.02 }
      }
    credit:
      - author: '[CnD] Xyonon'
        url: https://www.youtube.com/watch?v=dMdGeWnKaRA
        log: https://dps.report/Hjvb-20220312-192709_golem

  - name: Power Scrapper
    profession: Scrapper
    value: >-
      {
        "values2": { "Power": 3302, "Power2": 0, "Burning": 0, "Bleeding": 3.14, "Poisoned": 3.87, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Aralyth'
        url: https://www.youtube.com/watch?v=n0X117MhmlU
        log: https://dps.report/SJTF-20230216-171115_golem

  - name: Quickness Power Scrapper
    profession: Scrapper
    value: >-
      {
        "values2": { "Power": 3397, "Power2": 0, "Burning": 0, "Bleeding": 4.01, "Poisoned": 3.3, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: '[NA] Crone'
        url: https://www.youtube.com/watch?v=cKQ9-9gHe0Q
        log: https://dps.report/jbc7-20230707-213937_golem

  - name: Quickness Condi Scrapper
    profession: Scrapper
    value: >-
      {
        "values2": { "Power": 1952, "Power2": 0, "Burning": 8.91, "Bleeding": 11.34, "Poisoned": 11.87, "Torment": 0, "Confusion": 4.66 }
      }
    credit:
      - author: '[NA] Crone'
        url: https://www.youtube.com/watch?v=CqJpumNHF4E
        log: https://dps.report/eLRb-20230715-052530_golem

  - name: Power Chrono IA GS
    profession: Chronomancer
    value: >-
      {
        "values2": { "Power": 3541, "Power2": 1419, "Burning": 0, "Bleeding": 0.1, "Poisoned": 0, "Torment": 0, "Confusion": 0.93 }
      }
    credit:
      - author: Cemox
        url: https://www.youtube.com/watch?v=QumcjR3f3JU
        log: https://dps.report/mV8B-20231003-224244_golem

  # impact
  - name: Boon Power Chrono GS
    profession: Chronomancer
    value: >-
      {
        "values2": { "Power": 3640, "Power2": 1118, "Burning": 0, "Bleeding": 0.1, "Poisoned": 0, "Torment": 0, "Confusion": 1.24 }
      }
    credit:
      - author: '[CnD] Tipcat'
        url: https://www.youtube.com/watch?v=Z8Ni8RZ458k
        log: https://dps.report/wbK9-20231111-134616_golem

  - name: Alacrity Staff Mirage
    profession: Mirage
    value: >-
      {
        "values2": { "Power": 1162, "Power2": 175, "Burning": 0.01, "Bleeding": 9.75, "Poisoned": 0.2, "Torment": 12.12, "Confusion": 18.48 }
      }
    credit:
      - author: Mastro
        url: https://www.youtube.com/watch?v=RLll5GGvRjs
        log: https://dps.report/xC5s-20231003-215812_golem

  - name: Condi Staff / Axe Mirage
    profession: Mirage
    value: >-
      {
        "values2": { "Power": 1717, "Power2": 468, "Burning": 0, "Bleeding": 9.27, "Poisoned": 0.57, "Torment": 28.87, "Confusion": 15.05 }
      }
    credit:
      - author: Mastro
        url: https://www.youtube.com/watch?v=lGcntsRuwYI
        log: https://dps.report/cn4L-20231201-133205_golem

  - name: Axe Mirage (Deception Torch)
    profession: Mirage
    value: >-
      {
        "values2": { "Power": 2050, "Power2": 453, "Burning": 1.08, "Bleeding": 7.88, "Poisoned": 0.46, "Torment": 22.88, "Confusion": 11.84 }
      }
    credit:
      - author: Mastro
        url: https://www.youtube.com/watch?v=uzQnOVI7QBc
        log: https://dps.report/q02e-20231004-212315_golem

  # rit: shoulders coat gloves leggings, domi midnight, mischief, rendang icicle
  - name: Boon Condi Chrono
    profession: Chronomancer
    value: >-
      {
        "values2": { "Power": 1894, "Power2": 426, "Burning": 1.57, "Bleeding": 4.99, "Poisoned": 0, "Torment": 6.75, "Confusion": 16.42 }
      }
    credit:
      - author: '[Vs] Nico'
        url: https://www.youtube.com/watch?v=wvL3EvG7iZs
        log: https://dps.report/WItI-20220804-134453_golem

  # https://optimizer.discretize.eu/?s=ReeYNhV3v1
  # Above link out-of-date but good basis
  # 2 expertise 4 malign 12 precise infusions
  - name: Condi Virtuoso Dueling
    profession: Virtuoso
    value: >-
      {
        "values2": { "Power": 3200, "Power2": 930, "Burning": 0, "Bleeding": 0.03, "Poisoned": 0, "Torment": 8.98, "Confusion": 3.39 }
      }
    credit:
      - author: 'REMagic'
        url: https://www.youtube.com/watch?v=8GvA8WZTl0M
        log: https://dps.report/YeGx-20231205-223957_golem

  # https://optimizer.discretize.eu/?s=tC0aBKlpv1
  - name: Condi Virtuoso Chaos
    profession: Virtuoso
    value: >-
      {
        "values2": { "Power": 3146, "Power2": 9, "Burning": 0.52, "Bleeding": 3.08, "Poisoned": 0.27, "Torment": 7.58, "Confusion": 3.03 }
      }
    credit:
      - author: 'REMagic'
        url: https://www.youtube.com/watch?v=SgiCD0VZC1M
        log: https://dps.report/9Epv-20230630-181945_golem

  # accuracy, assassin legs, 4 precise (2007 prec)
  - name: Power Virtuoso GS
    profession: Virtuoso
    value: >-
      {
        "values2": { "Power": 3508, "Power2": 1106, "Burning": 0, "Bleeding": 0.16, "Poisoned": 0, "Torment": 0, "Confusion": 0.56 }
      }
    credit:
      - author: beng / L3m0n
        url: https://www.youtube.com/watch?v=dLs7hR9ZMFA
        log: https://dps.report/Wnpv-20230702-133715_golem

  - name: Condi Alacrity Scourge
    profession: Scourge
    value: >-
      {
        "values2": { "Power": 1656, "Power2": 0, "Burning": 1.77, "Bleeding": 13.54, "Poisoned": 5.16, "Torment": 13.25, "Confusion": 0 }
      }
    credit:
      - author: 'Sarulias'
        url: https://www.youtube.com/watch?v=6NFYb_RK5bQ
        log: https://dps.report/2bvf-20231014-100953_golem

  - name: Condi Scourge
    profession: Scourge
    value: >-
      {
        "values2": { "Power": 1799, "Power2": 0, "Burning": 2.94, "Bleeding": 12.77, "Poisoned": 4.91, "Torment": 13.37, "Confusion": 0 }
      }
    credit:
      - author: 'Incera'
        url: https://www.youtube.com/watch?v=mI7ky1a2-eQ
        log: https://dps.report/LzVj-20231003-151324_golem

  # "Reaper does approximately 29.28% of its damage outside of shroud" - https://discord.com/channels/380901000200060929/381160724409614356/828683461074092113
  # With YaaW this is closer to 33%
  # uses https://github.com/hobinjk/optimizer-scripts/blob/main/reaper.js for shroud power damage split
  - name: Power Reaper
    profession: Reaper
    value: >-
      {
        "values2": { "Power": 1580, "Power2": 2795, "Burning": 0, "Bleeding": 0.42, "Poisoned": 1.23, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Sarulias'
        url: https://www.youtube.com/watch?v=8ySVhgasBZ4
        log: https://dps.report/s1PP-20231007-214530_golem

  # uses https://github.com/hobinjk/optimizer-scripts/blob/main/reaper.js for shroud power damage split
  - name: Condi Reaper
    profession: Reaper
    value: >-
      {
        "values2": { "Power": 1637, "Power2": 1288, "Burning": 0.31, "Bleeding": 47.83, "Poisoned": 2.69, "Torment": 3.76, "Confusion": 0 }
      }
    credit:
      - author: 'Sarulias'
        url: https://www.youtube.com/watch?v=9x6c3mBoHHE
        log: https://dps.report/O08l-20231002-152104_golem

  - name: DPS Harbinger
    profession: Harbinger
    value: >-
      {
        "values2": { "Power": 2314, "Power2": 0, "Burning": 2, "Bleeding": 7.93, "Poisoned": 6.36, "Torment": 20.8, "Confusion": 0.65 }
      }
    credit:
      - author: 'Sarulias'
        url: https://www.youtube.com/watch?v=ZOC2RsEFrTY
        log: https://dps.report/IpSJ-20231002-151234_golem

  - name: Quickness Harbinger
    profession: Harbinger
    value: >-
      {
        "values2": { "Power": 2252, "Power2": 0, "Burning": 1.93, "Bleeding": 9.28, "Poisoned": 6.08, "Torment": 17.58, "Confusion": 0.64 }
      }
    credit:
      - author: 'Sarulias'
        url: https://www.youtube.com/watch?v=hGy1hhhvhhc
        log: https://dps.report/9GSF-20231002-152522_golem

  - name: Quickness Power Harbinger (Approx.)
    profession: Harbinger
    value: >-
      {
        "values2": { "Power": 1589, "Power2": 1382, "Burning": 0.13, "Bleeding": 2.44, "Poisoned": 2.14, "Torment": 9.49, "Confusion": 0.13 }
      }
    credit:
      - author: '[CnD] Aphrodite Lady'
        url: https://www.youtube.com/watch?v=hyunt1U3YHQ
        log: https://dps.report/wtGt-20220902-132134_golem

  - name: Condi Deadeye
    profession: Deadeye
    value: >-
      {
        "values2": { "Power": 1690, "Power2": 0, "Burning": 0.5, "Bleeding": 19, "Poisoned": 14.6, "Torment": 9.5, "Confusion": 0 }
      }
    credit:
      - author: 'Incera'
        url: https://www.youtube.com/watch?v=1yAvjWJoAyE
        log: https://dps.report/zCoq-20211001-043146_golem

  - name: Power Staff Daredevil
    profession: Daredevil
    value: >-
      {
        "values2": { "Power": 3346, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 3.02, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Left'
        url: https://www.youtube.com/watch?v=j_KEqAhxgjc
        log: https://dps.report/GlYP-20230826-115834_golem

  - name: Condi Daredevil (No Allies)
    profession: Daredevil
    value: >-
      {
        "values2": { "Power": 1348, "Power2": 0, "Burning": 0, "Bleeding": 25.72, "Poisoned": 10.39, "Torment": 1.66, "Confusion": 0 }
      }
    credit:
      - author: 'Left'
        url: https://www.youtube.com/watch?v=gKnb5aRz5nk
        log: https://www.dps.report/84Ov-20230216-152900_golem

  # messed up log start from mesmer existing
  # scuffed approximation by taking total damage and dividing by approximate duration (99.143)
  - name: Condi Daredevil
    profession: Daredevil
    value: >-
      {
        "values2": { "Power": 1513, "Power2": 0, "Burning": 0, "Bleeding": 25.8, "Poisoned": 16.09, "Torment": 1.74, "Confusion": 0 }
      }
    credit:
      - author: 'Left'
        url: https://www.youtube.com/watch?v=gKnb5aRz5nk
        log: https://www.dps.report/WZp4-20230216-142041_golem

  - name: Power Deadeye M7
    profession: Deadeye
    value: >-
      {
        "values2": { "Power": 3805, "Power2": 0, "Burning": 0, "Bleeding": 1.19, "Poisoned": 2.48, "Torment": 0.63, "Confusion": 0 }
      }
    credit:
      - author: 'Incera'
        url: https://www.youtube.com/watch?v=F4KeTOtfzY8
        log: https://dps.report/MXrd-20230915-172015_golem

  - name: Power Deadeye Daggers BQoBK
    profession: Deadeye
    value: >-
      {
        "values2": { "Power": 3192, "Power2": 0, "Burning": 0, "Bleeding": 3.86, "Poisoned": 6.7, "Torment": 0, "Confusion": 0 }
      }
    credit:
      - author: 'Iskarel'
        url: https://www.youtube.com/watch?v=roV_hRP7AVY
        log: https://dps.report/tpmp-20221130-042530_golem

  - name: Rifle Deadeye Premeditation
    profession: Deadeye
    value: >-
      {
        "values2": { "Power": 3669, "Power2": 0, "Burning": 0, "Bleeding": 0.42, "Poisoned": 1.83, "Torment": 0.5, "Confusion": 0 }
      }
    credit:
      - author: 'Iskarel'
        url: https://www.youtube.com/watch?v=52La8OgG69w
        log: https://dps.report/yDtd-20230214-221732_golem

  - name: Rifle Deadeye Silent Scope
    profession: Deadeye
    value: >-
      {
        "values2": { "Power": 3907, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 0.95, "Torment": 0.24, "Confusion": 0 }
      }
    credit:
      - author: 'Iskarel'
        url: https://www.youtube.com/watch?v=iou6Wx7NQ4w
        log: https://dps.report/OK9l-20230214-214155_golem

  # 1.72 torment proc rate (from evtc tool applied to previous log)
  - name: DPS Specter SC/D (Allies)
    profession: Specter
    value: >-
      {
        "values2": { "Power": 2437, "Power2": 0, "Burning": 0, "Bleeding": 2.53, "Poisoned": 14.31, "Torment": 16.75, "Confusion": 0 }
      }
    credit:
      - author: 'Incera'
        url: https://www.youtube.com/watch?v=5mBLfyDqmSo
        log: https://dps.report/dxtA-20240624-182549_golem

  # https://optimizer.discretize.eu/?s=wpWaF0plv1
  # bugged log: has leeching venoms
  # 1.52 torment proc rate (from evtc tool)
  - name: DPS Barrier Specter SC/D (Allies)
    profession: Specter
    value: >-
      {
        "values2": { "Power": 2163, "Power2": 0, "Burning": 0, "Bleeding": 2.55, "Poisoned": 15.94, "Torment": 20.03, "Confusion": 0 }
      }
    credit:
      - author: 'Iskarel'
        url: https://www.youtube.com/watch?v=xYsGEFE6jn0
        log: https://dps.report/pfgp-20220827-121511_golem

  # 1.52 torment proc rate (from evtc tool on carrion spec, which has the same critchance)
  - name: Alacrity Specter SC/D (allies)
    profession: Specter
    value: >-
      {
        "values2": { "Power": 1903, "Power2": 0, "Burning": 0, "Bleeding": 0, "Poisoned": 14.33, "Torment": 18.23, "Confusion": 0 }
      }
    credit:
      - author: 'Iskarel'
        url: https://www.youtube.com/watch?v=m24fDZee1bc
        log: https://dps.report/KKWN-20220902-160446_golem

  - name: Alacrity Specter D/D (allies)
    profession: Specter
    value: >-
      {
        "values2": { "Power": 2467, "Power2": 0, "Burning": 0, "Bleeding": 21.02, "Poisoned": 13.38, "Torment": 6.37, "Confusion": 0 }
      }
    credit:
      - author: 'Iskarel'
        url: https://www.youtube.com/watch?v=ziK2tPRR0Vw
        log: https://dps.report/CVSb-20240512-132756_golem
