GraphQL ID: presetInfusions
list:

  - name: None
    value: |-
      {
        "primaryInfusion": "",
        "secondaryInfusion": "",
        "primaryMaxInfusions": "",
        "secondaryMaxInfusions": ""
      }

  - name: Power + Precision
    value: |-
      {
        "primaryInfusion": "Power",
        "secondaryInfusion": "Precision",
        "primaryMaxInfusions": "",
        "secondaryMaxInfusions": ""
      }

  - name: Condition Damage + Expertise
    value: |-
      {
        "primaryInfusion": "Condition Damage",
        "secondaryInfusion": "Expertise",
        "primaryMaxInfusions": "",
        "secondaryMaxInfusions": ""
      }

  - name: Condition Damage + Precision
    value: |-
      {
        "primaryInfusion": "Condition Damage",
        "secondaryInfusion": "Precision",
        "primaryMaxInfusions": "",
        "secondaryMaxInfusions": ""
      }

  - name: Healing Power + Concentration
    value: |-
      {
        "primaryInfusion": "Healing Power",
        "secondaryInfusion": "Concentration",
        "primaryMaxInfusions": "",
        "secondaryMaxInfusions": ""
      }
