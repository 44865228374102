- section: Skills
  items:

    - id: signet-of-fire
      text: Signet of Fire
      modifiers:
        attributes:
          Precision: [180, buff]
      gw2id: 5542
      temporaryBuff: false

    - id: signet-of-earth
      text: Signet of Earth
      modifiers:
        damage:
          Damage Reduction: [10%, unknown]
      gw2id: 5571

    - id: woven-fire
      text: Woven Fire
      subText: woven fire / perfect weave
      amountData:
        label: '% uptime'
        default: 38
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Condition Damage: [20%, add]
      gw2id: 43638

    - id: woven-air
      text: Woven Air
      subText: woven air / perfect weave
      amountData:
        label: '% uptime'
        default: 36
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, unknown]
      gw2id: 43638

    - id: flame-wheel
      text: Flame Wheel
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, unknown]
          Outgoing Condition Damage: [15%, add] #unconfirmed
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, unknown]
          Outgoing Condition Damage: [10%, add] #unconfirmed
      gw2id: 62758

    - id: crescent-wind
      text: Crescent Wind
      subText: 100% uptime
      modifiers:
        attributes:
          Critical Chance: 15%
      wvwModifiers:
        attributes:
          Critical Chance: 12%
      gw2id: 62887
      temporaryBuff: true

    - id: relentless-fire
      text: Relentless Fire
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, unknown]
          Outgoing Condition Damage: [10%, add] #unconfirmed
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [15%, unknown]
          Outgoing Condition Damage: [15%, add] #unconfirmed
      gw2id: 62965

    - id: raging-ricochet
      text: Raging Ricochet
      amountData:
        label: '% uptime'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Condition Damage: [5%, add] # unconfirmed
      gw2id: 71828

    - id: arcane-power
      text: Arcane Power
      subText: ferocity only
      amountData:
        label: '% uptime'
        default: 0
        defaultInput: ???
        quantityEntered: 100
      modifiers:
        attributes:
          Ferocity: [300, unknown]
      gw2id: 5635
      temporaryBuff: true

    # - id: flame-axe
    #   text: Flame Axe
    #   subText: when equipped
    #   modifiers:
    #     attributes:
    #       Power: [180, buff]
    #       Condition Damage: [180, buff]
    #   gw2id: 5540

    # - id: earth-shield
    #   text: Earth Shield
    #   subText: when equipped
    #   modifiers:
    #     attributes:
    #       Vitality: [180, buff]
    #       Toughness: [180, buff]
    #   gw2id: 5546

    # - id: fiery-greatsword
    #   text: Fiery Greatsword
    #   subText: when equipped
    #   modifiers:
    #     attributes:
    #       Power: [260, buff]
    #       Condition Damage: [180, buff]
    #   gw2id: 5516

    # - id: lightning-hammer
    #   text: Lightning Hammer
    #   subText: when equipped
    #   modifiers:
    #     attributes:
    #       Precision: [180, buff]
    #       Ferocity: [75, buff]
    #   gw2id: 5624

    # - id: frost-bow
    #   text: Frost Bow
    #   subText: when equipped
    #   modifiers:
    #     attributes:
    #       Healing Power: [180, buff]
    #       Condition Duration: 20%
    #   gw2id: 5567

- section: Fire
  id: 31
  items:

    - id: empowering-flame
      text: Empowering Flame
      minor: true
      amountData:
        label: '% fire attunement'
        default: 50
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [150, buff]
      gw2id: 320
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat # assume the user is in fire attunement, not air

    - id: burning-precision
      text: Burning Precision
      subText: base
      modifiers:
        attributes:
          Burning Duration: 20%
      wvwModifiers:
        attributes:
          Burning Duration: 15%
      gw2id: 296
      defaultEnabled: true
      temporaryBuff: false

    # - id: burning-precision-on-crit
    #   text: Burning Precision
    #   amountData:
    #     label: '/10s'
    #     default: 1.9
    #     quantityEntered: 10
    #   modifiers:
    #     attributes:
    #       Burning Coefficient: 3
    #   gw2id: 296
    #   defaultEnabled: false

    - id: burning-rage
      text: Burning Rage
      modifiers:
        attributes:
          Condition Damage: [180, buff]
      gw2id: 325
      defaultEnabled: true
      temporaryBuff: false

    - id: power-overwhelming
      text: Power Overwhelming
      subText: permanent
      modifiers:
        attributes:
          Power: [150, buff]
      gw2id: 334
      defaultEnabled: true
      temporaryBuff: false

    - id: power-overwhelming-2
      text: Power Overwhelming
      amountData:
        label: '% fire attunement'
        default: 50
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [150, buff]
      gw2id: 334
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat # assume the user is in fire attunement, not air

    - id: pyromancers-training
      text: Pyromancer's Training
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 319
      defaultEnabled: true

    - id: persisting-flames
      text: Persisting Flames
      amountData:
        label: 'stacks'
        default: 9
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [1%, add]
      gw2id: 1510
      defaultEnabled: true

- section: Air
  id: 41
  items:

    - id: zephyrs-speed
      text: Zephyr's Speed
      minor: true
      modifiers:
        attributes:
          Critical Chance: 5%
      gw2id: 221
      defaultEnabled: true
      temporaryBuff: false

    - id: ferocious-winds
      text: Ferocious Winds
      modifiers:
        conversion:
          Ferocity: {Precision: 7%}
      gw2id: 232
      defaultEnabled: true
      temporaryBuff: false

    - id: raging-storm
      text: Raging Storm
      amountData:
        label: '% fury'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Ferocity: [180, buff]
      gw2id: 214
      defaultEnabled: true
      temporaryBuff: true

    - id: stormsoul
      text: Stormsoul
      subText: disabled/defiant
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 1502
      defaultEnabled: true

    - id: aeromancers-training
      text: Aeromancer's Training
      subText: permanent
      minor: true
      modifiers:
        attributes:
          Ferocity: [150, buff]
      gw2id: 223
      defaultEnabled: true
      temporaryBuff: false

    - id: aeromancers-training-2
      text: Aeromancer's Training
      amountData:
        label: '% air attunement'
        default: 50
        quantityEntered: 100
      minor: true
      modifiers:
        attributes:
          Ferocity: [150, buff]
      gw2id: 223
      defaultEnabled: true
      temporaryBuff: true # assume the user is in fire attunement, not air

    - id: bolt-to-the-heart
      text: Bolt to the Heart
      amountData:
        label: '% below 50'
        default: 50
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [20%, mult]
      gw2id: 226
      defaultEnabled: true

    - id: fresh-air
      text: Fresh Air
      amountData:
        label: '% uptime'
        default: 50
        quantityEntered: 100
      modifiers:
        attributes:
          Ferocity: [250, buff]
      gw2id: 1503
      defaultEnabled: true
      temporaryBuff: true

- section: Earth
  id: 26
  items:

    - id: stone-flesh
      text: Stone Flesh
      minor: true
      subText: 100% earth attunement
      modifiers:
        damage:
          Damage Reduction: [7%, unknown]
      gw2id: 278
      defaultEnabled: false

    - id: serrated-stones
      text: Serrated Stones
      modifiers:
        damage:
          Outgoing Strike Damage: [5%, mult]
        attributes:
          Bleeding Duration: 20%
      gw2id: 1507
      defaultEnabled: true
      temporaryBuff: false

    - id: strength-of-stone
      text: Strength of Stone
      modifiers:
        conversion:
          Condition Damage: {Toughness: 10%}
      gw2id: 275
      defaultEnabled: true
      temporaryBuff: false

    - id: geomancers-training
      text: Geomancer's Training
      minor: true
      modifiers:
        damage:
          Damage Reduction: [10%, unknown]
      gw2id: 280
      defaultEnabled: true

- section: Water
  id: 17
  items:

    - id: piercing-shards
      text: Piercing Shards
      subText: base
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [5%, mult]
      gw2id: 363
      defaultEnabled: true

    - id: piercing-shards-2
      text: Piercing Shards
      amountData:
        label: '% water attunement'
        default: 5
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [5%, mult]
      gw2id: 363
      defaultEnabled: true

    - id: flow-like-water-low
      text: Flow like Water
      subText: 'low health'
      amountData:
        label: '% uptime'
        default: 0
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [5%, mult] # unconfirmed
      gw2id: 349
      defaultEnabled: false

    - id: flow-like-water
      text: Flow like Water
      subText: 'high health'
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 349
      defaultEnabled: true

    - id: aquamancers-training
      text: Aquamancer's Training
      modifiers:
        attributes:
          Outgoing Healing: 20%
      minor: true
      gw2id: 1676
      defaultEnabled: true
      temporaryBuff: false

    - id: soothing-power
      text: Soothing Power
      modifiers:
        attributes:
          Vitality: [300, buff]
      gw2id: 2028
      defaultEnabled: true
      temporaryBuff: false

- section: Arcane
  id: 37
  items:

    - id: elemental-enchantment
      text: Elemental Enchantment
      modifiers:
        attributes:
          Concentration: [180, buff]
      wvwModifiers:
        attributes:
          Concentration: [120, buff]
      minor: true
      gw2id: 2004
      defaultEnabled: true
      temporaryBuff: false

    - id: arcane-lightning
      text: Arcane Lightning
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Ferocity: [150, converted]
      gw2id: 263
      defaultEnabled: true
      temporaryBuff: true

    - id: bountiful-power
      text: Bountiful Power
      amountData:
        label: 'boons'
        default: 10
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [2%, mult]
      gw2id: 1511
      defaultEnabled: true

- section: Tempest
  id: 48
  items:

    - id: gathered-focus
      text: Gathered Focus
      subText: base amount
      modifiers:
        attributes:
          Concentration: [240, buff]
      wvwModifiers:
        attributes:
          Concentration: [120, buff] #it would be an additional 60 if above 90% hp. But i dont want to asume that in a wvw setting, so i go with the lower one in case i want to optimize for boon duration
      minor: true
      gw2id: 1938
      defaultEnabled: true
      temporaryBuff: false

    - id: hardy-conduit
      text: Hardy Conduit
      minor: true
      modifiers:
        damage:
          Damage Reduction: [6.6%, unknown]
      gw2id: 1948
      defaultEnabled: true

    - id: transcendent-tempest
      text: Transcendent Tempest
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [25%, add]
          Outgoing Condition Damage: [25%, add]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, add]
          Outgoing Condition Damage: [7%, add]
      gw2id: 1839
      defaultEnabled: true

    - id: tempestuous-aria
      text: Tempestuous Aria
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, unknown]
          Outgoing Condition Damage: [5%, add] # unconfirmed
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, unknown]
          Outgoing Condition Damage: [7%, add] # unconfirmed
      gw2id: 1891
      defaultEnabled: true

- section: Weaver
  id: 56
  items:

    - id: superior-elements
      text: Superior Elements
      subText: 100% uptime
      modifiers:
        attributes:
          Critical Chance: 15%
      wvwModifiers:
        attributes:
          Critical Chance: 15%
      gw2id: 2177
      defaultEnabled: true
      temporaryBuff: true

    - id: masters-fortitude
      text: Master's Fortitude
      subText: base
      modifiers:
        conversion:
          Vitality: {Power: 5%, Condition Damage: 5%}
      gw2id: 2115
      defaultEnabled: true
      temporaryBuff: false

    - id: masters-fortitude-sword
      text: Master's Fortitude
      subText: with sword
      modifiers:
        attributes:
          Vitality: [120, converted]
      gw2id: 2115
      defaultEnabled: true
      temporaryBuff: false # since you only have one weapon set

    - id: weavers-prowess
      text: Weaver's Prowess
      subText: 100% uptime
      modifiers:
        damage:
          Outgoing Condition Damage: [5%, add]
        attributes:
          Condition Duration: 20%
      wvwModifiers:
        damage:
          Outgoing Condition Damage: [10%, add]
        attributes:
          Condition Duration: 7%
      gw2id: 2180
      defaultEnabled: true
      temporaryBuff: true

    - id: swift-revenge
      text: Swift Revenge
      amountData:
        label: '% swiftness'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, add]
      gw2id: 2061
      defaultEnabled: true

    - id: elemental-polyphony-fire
      text: Elemental Polyphony
      minor: true
      amountData:
        label: '% fire attunement'
        default: 50
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [120, buff]
      gw2id: 2081
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat # assume the user is in fire attunement, not air

    - id: elemental-polyphony-air
      text: Elemental Polyphony
      minor: true
      amountData:
        label: '% air attunement'
        default: 50
        quantityEntered: 100
      modifiers:
        attributes:
          Ferocity: [120, buff]
      gw2id: 2081
      defaultEnabled: true
      temporaryBuff: true # assume the user is in fire attunement, not air

    - id: elements-of-rage-base
      text: Elements of Rage
      subText: base
      modifiers:
        conversion:
          Precision: {Vitality: 13%}
      gw2id: 2131
      defaultEnabled: true
      temporaryBuff: false

    - id: elements-of-rage-buff
      text: Elements of Rage
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Condition Damage: [7%, add]
          Outgoing Strike Damage: [7%, add] # untested; please confirm!
      wvwModifiers:
        damage:
          Outgoing Condition Damage: [5%, add]
          Outgoing Strike Damage: [5%, add] # untested; please confirm!
      gw2id: 2131
      defaultEnabled: true

- section: Catalyst
  id: 67
  note: Non-constant critical chance is not currently simulated. Using dragon gear to take advantage of >100% critical chance with full berserker may be a DPS loss in reality.
  items:

    - id: hardened-auras
      text: Hardened Auras
      amountData:
        label: 'stacks'
        default: 5
        quantityEntered: 1
        disableBlacklist: true
      modifiers:
        damage:
          Damage Reduction: [2%, unknown]
      gw2id: 2230
      defaultEnabled: true

    - id: elemental-empowerment
      text: Elemental Empowerment
      subText: 10x
      minor: true
      modifiers:
        conversion:
          Power: {Power: 10%}
          Precision: {Precision: 10%}
          Toughness: {Toughness: 10%}
          Vitality: {Vitality: 10%}
          Concentration: {Concentration: 10%}
          Condition Damage: {Condition Damage: 10%}
          Expertise: {Expertise: 10%}
          Ferocity: {Ferocity: 10%}
          Healing Power: {Healing Power: 10%}
      gw2id: 2250
      defaultEnabled: true
      temporaryBuff: true

    - id: empowering-auras
      text: Empowering Auras
      amountData:
        label: 'stacks'
        default: 2
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [2%, unknown]
          Outgoing Condition Damage: [2%, add] #unconfirmed
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [2%, unknown]
          Outgoing Condition Damage: [2%, add] #unconfirmed
      gw2id: 2247
      defaultEnabled: true

    - id: spectacular-sphere
      text: Spectacular Sphere
      modifiers: {}
      wvwModifiers: {}
      gw2id: 2234
      defaultEnabled: true

    - id: empowered-empowerment
      text: Empowered Empowerment
      subText: 10x empowerment
      modifiers:
        conversion:
          Power: {Power: 10%}
          Precision: {Precision: 10%}
          Toughness: {Toughness: 10%}
          Vitality: {Vitality: 10%}
          Concentration: {Concentration: 10%}
          Condition Damage: {Condition Damage: 10%}
          Expertise: {Expertise: 10%}
          Ferocity: {Ferocity: 10%}
          Healing Power: {Healing Power: 10%}
      gw2id: 2241
      defaultEnabled: true
      temporaryBuff: true
