- section: Boons
  items:
    - id: might
      text: Might
      amountData:
        label: 'x'
        default: 25
        quantityEntered: 1
      modifiers:
        attributes:
          Power: [30, buff]
          Condition Damage: [30, buff]
      type: Boon
      temporaryBuff: true

    - id: fury
      text: Fury
      modifiers:
        attributes:
          Critical Chance: 25%
      wvwModifiers:
        attributes:
          Critical Chance: 20%
      type: Boon
      temporaryBuff: true

    - id: protection
      text: Protection
      modifiers:
        damage:
          Damage Reduction: [33%, mult]
      type: Boon

    - id: vulnerability
      text: Vulnerability
      amountData:
        label: 'x'
        default: 25
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [1%, target]
          Outgoing Condition Damage: [1%, target]
          Outgoing Phantasm Damage: [1%, target]
      type: Condition

- section: Other buffs
  items:
    - id: jade-bot-base
      text: Jade Bot
      subText: (select both boxes)
      modifiers:
        attributes:
          Vitality: [85, converted]
      type: Text
      temporaryBuff: false

    - id: jade-bot-per-tier
      text: Jade Bot Tier
      amountData:
        label: ''
        default: 10
        quantityEntered: 1
      modifiers:
        attributes:
          Vitality: [15, converted]
      type: Text
      temporaryBuff: false

    - id: reinforced-armor
      text: Reinforced Armor
      modifiers:
        conversion:
          Vitality: {Vitality: 5%} # tested: https://discord.com/channels/380901000200060929/380903574282174466/1025093797304090685
          Armor: {Armor: 5%}
      wvwModifiers:
        conversion:
          Vitality: {Vitality: 1%}
          Armor: {Armor: 1%}
      type: Text
      temporaryBuff: false

- section: Effects
  items:
    - id: exposed
      text: Exposed
      amountData:
        label: '%'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, target]
          Outgoing Condition Damage: [20%, target]
          Outgoing Phantasm Damage: [10%, target]
      type: CommonEffect

    - id: lightArmor
      text: Low Boss Armor (VG, KC)
      subText: 1910 vs 2597
      modifiers:
        damage:
          Outgoing Strike Damage: [35.9686%, mult]
          Outgoing Phantasm Damage: [35.9686%, mult]
      type: Text

    - id: lightArmor2
      text: Low Boss Armor (HT)
      subText: 2141 vs 2597
      modifiers:
        damage:
          Outgoing Strike Damage: [21.2985%, mult]
          Outgoing Phantasm Damage: [21.2985%, mult]
      type: Text

    - id: lightArmor3
      text: Low Boss Armor (Qadim)
      subText: 2293 vs 2597 # tested by remagic
      modifiers:
        damage:
          Outgoing Strike Damage: [13.2577%, mult]
          Outgoing Phantasm Damage: [13.2577%, mult]
      type: Text
