GraphQL ID: presetBuffs
list:
  - name: None
    value: >-
      {
      }

  - name: Realistic
    value: >-
      {
        "might": true,
        "fury": true,
        "protection": true,
        "vulnerability": true,
        "jade-bot-base": true,
        "jade-bot-per-tier": true,
        "reinforced-armor": true
      }

  - name: Condi
    hidden: true
    value: >-
      {
        "might": true,
        "fury": true,
        "protection": true,
        "vulnerability": true,
        "assassinsPresence": true,
        "jade-bot-base": true,
        "jade-bot-per-tier": true,
        "reinforced-armor": true
      }

  - name: Power (no spotter)
    hidden: true
    value: >-
      {
        "might": true,
        "fury": true,
        "protection": true,
        "vulnerability": true,
        "frostSpirit": true,
        "assassinsPresence": true,
        "jade-bot-base": true,
        "jade-bot-per-tier": true,
        "reinforced-armor": true
      }

  - name: Power (spotter)
    hidden: true
    value: >-
      {
        "might": true,
        "fury": true,
        "protection": true,
        "vulnerability": true,
        "spotter": true,
        "frostSpirit": true,
        "assassinsPresence": true,
        "jade-bot-base": true,
        "jade-bot-per-tier": true,
        "reinforced-armor": true
      }

  - name: Benchmark
    hidden: true
    value: >-
      {
        "might": true,
        "fury": true,
        "protection": true,
        "vulnerability": true,
        "bannerOfStrength": true,
        "bannerOfDiscipline": true,
        "empowerAllies": true,
        "frostSpirit": true,
        "spotter": true,
        "jade-bot-base": true,
        "jade-bot-per-tier": true,
        "reinforced-armor": true
      }
